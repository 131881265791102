import { Config } from "../config.js";
const Keahlian = ({ data }) => {
    return (
        <div className="space-y-20 ps-5 pe-5">
            <div className="flex flex-col lg:flex-row px-2 mt-6 mb-20 container mx-auto justify-center gap-10 items-center">
                <div className="flex flex-col gap-4 text-justify">
                    <h3 className="font-bold uppercase tracking-tight text-xl text-center">Tujuan Program Keahlian {data.attributes?.Program}</h3>
                    <p>
                        {data.attributes?.Text1}
                    </p>
                    <ol className="list-decimal px-4">
                        {data.attributes?.Text.map((item, index) => (
                            <li key={index}>{item.children[0].text}</li>
                        ))}
                    </ol>
                </div>
                <img src={`${Config.Api}` + data.attributes?.Image.data[0].attributes.url} alt="Keahlian" />
            </div>
        </div>
    );
};

export default Keahlian