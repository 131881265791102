import { Menu } from "@headlessui/react";

function Dropdown({ trigger, children }) {
  return (
    <Menu>
      <Menu.Button className="flex items-center justify-between w-full py-2 px-3  md:hover:bg-transparent md:hover:border-b-2 md:hover:border-white md:p-0 md:w-auto text-white">
        {trigger}
        <svg className="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
        </svg>
      </Menu.Button>
      <Menu.Items>
        <Menu.Item
          className={`md:z-10 font-normal bg-red-600 divide-y divide-gray-100 rounded-lg shadow w-full md:w-44  md:absolute md:bg-red-500 overflow-hidden`}
        >
          <ul className="text-sm text-white dark:text-gray-200" aria-labelledby="dropdownLargeButton">
            <li className="space-y-1">{children}</li>
          </ul>
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
}
export default Dropdown;