import React, { useEffect } from "react";

import Navbar from "../Component/Navbar";
import Headers from "../Component/Headers";
import VisiMisiPage from "../Component/Visi-misi"
import Footer from "../Component/Footer";

function VisiMisi() {

    useEffect(() => {
        document.title = "Visi Dan Misi | SMK BUDDHI";

        return () => {
            document.title = "SMK BUDDHI";
        }
    }, []);
    return (
        <div className="VisiMisi">
            <Navbar />
            <Headers title="Visi Dan Misi" desc="Visi dan Misi Sekolah Menengah Kejuruan Perguruan Buddhi." />
            <VisiMisiPage />
            <footer className="bg-red-500">
                <Footer />
            </footer>
        </div>
    );
}
export default VisiMisi;
