import { Config } from "../config.js";
import axios from "axios";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import Navbar from "../Component/Navbar";
import Headers from "../Component/Headers";
import Footer from "../Component/Footer";
import PaginationFasilitas from "../Component/PaginationFasilitas";


function ImageDummy({ data }) {
  return (
    <div data-aos="fade-down" className="bg-white rounded-xl shadow-xl p-5 space-y-2">
      <img src={`${Config.Api}` + data.attributes?.Image.data[0].attributes.url} alt="Fasilitas" className="rounded-xl" />
      <div className="text-lg font-bold bottom-3 left-6">{data.attributes?.Fasilitas}</div>
    </div>
  );
}

function Fasilitas() {
  // PAGE
  const [searchParams] = useSearchParams();
  const Page = searchParams.get("page");

  // GALLERY
  const [fasilitas, setFasilitas] = useState({});

  useEffect(() => {
    fetchData(Page);
    document.title = "Fasilitas | SMK BUDDHI";

    return () => {
      document.title = "SMK BUDDHI";
    }
  }, [Page]);

  const fetchData = (Page) => {
    axios
      .get(`${Config.Api}/api/fasilitasses?populate=%2A&pagination[page]=${Page === null ? 1 : Page}&pagination[pageSize]=6&sort[1]=createdAt:DESC`)
      .then((response) => {
        if (response.data) {
          setFasilitas(response.data || {});
        } else {
          console.error("Data not found in response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };


  return (
    <div className="Fasilitas">
      <Navbar />
      <Headers title="Fasilitas" desc="Fasilitas di SMK Buddhi" />

      <div className="space-y-10 pt-6 flex flex-col justify-center items-center">
        <section className="ps-5 pe-5 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 container mx-auto">
          {Object.keys(fasilitas).length > 0 && fasilitas.data.map((data, index) => <ImageDummy key={index} data={data} />)}
        </section>

        {Object.keys(fasilitas).length > 0 ? <PaginationFasilitas Page={Page === null ? 1 : Page} Data={fasilitas} /> : ""}

      </div>
      <footer className="bg-red-500">
        <Footer />
      </footer>
    </div>
  );
}
export default Fasilitas;
