import { Config } from "../config.js";
import axios from "axios";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";

import Navbar from "../Component/Navbar";
import Headers from "../Component/Headers";
import Footer from "../Component/Footer";
import PaginationPrestasi from "../Component/PaginationPrestasi";

function ImageDummy({ data }) {
  return (
    <div data-aos="fade-down" className="bg-white rounded-xl shadow-xl p-5 space-y-2">
      <img src={`${Config.Api}` + data.attributes?.Image.data[0].attributes.url} alt="Prestasi" className="rounded-xl" />
      <div className="text-lg font-bold bottom-3 left-6">{data.attributes?.Judul}</div>
    </div>
  );
}

function Prestasi() {
  // PAGE
  const [searchParams] = useSearchParams();
  const Page = searchParams.get("page");

  // SEARCH
  const [searchTerm, setSearchTerm] = useState("");

  // GALLERY
  const [prestasi, setPrestasi] = useState({});
  const [prestasiFull, setPrestasiFull] = useState({});

  useEffect(() => {
    fetchData(Page);
    document.title = "Prestasi | SMK BUDDHI";

    return () => {
      document.title = "SMK BUDDHI";
    }
  }, [Page, prestasiFull, searchTerm]);

  const fetchData = (Page) => {
    axios
      .get(`${Config.Api}/api/prestasis?populate=%2A&pagination[page]=${Page === null ? 1 : Page}&pagination[pageSize]=6&sort[1]=createdAt:DESC`)
      .then((response) => {
        if (response.data) {
          setPrestasi(response.data || {});
        } else {
          console.error("Data not found in response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchDataFull = (value) => {
    axios
      .get(`${Config.Api}/api/prestasis?populate=%2A&sort[1]=createdAt:DESC`)
      .then((response) => {
        if (response.data) {
          if(value === '') return setPrestasiFull({})
          setPrestasiFull(response.data.data.filter(dat => {
            return dat.attributes?.Judul.toLowerCase().includes(value.toLowerCase());
          }) || {});
        } else {
          console.error("Data not found in response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    fetchDataFull(value);
  };

  return (
    <div className="Prestasi">
      <Navbar />
      <Headers title="Prestasi" desc="Prestasi SMK BUDDHI" />

      <div className="space-y-10 pt-6 flex flex-col justify-center items-center">
        {/* Search bar */}
        <div className="flex px-2 mt-6 container justify-end">
          <div className="relative w-[400px]">
            <input className="w-full bg-gray-100 border rounded py-2 px-4" placeholder="Search" onChange={(e) => handleSearch(e.target.value)} />
            <AiOutlineSearch className="absolute top-2 right-2 h-6 w-6 text-gray-500" />
          </div>
        </div>
        {/* Search bar end*/}

        <section className="ps-5 pe-5 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 container mx-auto">
          {prestasiFull.length === 0 || prestasiFull.length === undefined ?
            (Object.keys(prestasi).length > 0 && prestasi.data.map((data, index) => <ImageDummy key={index} data={data} />)) :
            (Object.keys(prestasiFull).length > 0 && prestasiFull.map((data, index) => <ImageDummy key={index} data={data} />))
          }
        </section>
        {Object.keys(prestasi).length > 0 ? <PaginationPrestasi Page={Page === null ? 1 : Page} Data={prestasi} /> : ""}

      </div>
      <footer className="bg-red-500">
        <Footer />
      </footer>
    </div>
  );
}
export default Prestasi;
