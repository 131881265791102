import { Config } from '../config';
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

function ImageDummy({ number, image }) {
    return (
        <div data-aos="fade-down" className="relative rounded-xl ps-5 pe-5 pb-3 shadow-xl">
            <img src={image} alt="Gallery" />
            <div className="text-lg font-bold bottom-3 left-6">{number}</div>
        </div>
    );
}

function ImageDummyPop({ number, image }) {
    return (
        <div data-aos="fade-down" className="relative rounded-xl shadow-xl">
            <img src={image} alt="Gallery" />
        </div>
    );
}

function Popup({ isOpen, closeModal, number, desc, image }) {
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                                {/* <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"> */}
                                <Dialog.Title className="bg-red-500 px-6 py-4 font-bold text-white">Detail Image</Dialog.Title>

                                <div className="flex flex-col divide-y">
                                    <div className="space-y-4 p-4 ">
                                        <ImageDummyPop image={image} number={number} />
                                        <div className="space-y-2">
                                            <h4 className="text-lg font-bold bottom-3 left-6">{number}</h4>
                                            <p>{desc}</p>
                                        </div>
                                    </div>
                                    <div className="p-4 flex justify-end">
                                        <button onClick={closeModal} className="bg-blue-500 rounded-lg text-white py-2 px-4">
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}

const GalleryImage = ({ Data, Search }) => {
    let [isOpen, setIsOpen] = useState(false);

    function closeModal() {
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
    }

    return (
        <div key={Data.id} className='pe-5 ps-5 pb-5'>
            <button type="button" onClick={openModal}>
                <ImageDummy number={Data.attributes?.Judul} image={`${Config.Api}` + Data.attributes?.Image.data[0].attributes.url} />
            </button>

            {Data.id && <Popup number={Data.attributes?.Judul} desc={Data.attributes?.Desc} image={`${Config.Api}` + Data.attributes?.Image.data[0].attributes.url} isOpen={isOpen} closeModal={closeModal} />}
        </div>
    );
};

export default GalleryImage