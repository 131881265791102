import { Config } from "../config";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";


import Navbar from "../Component/Navbar";
import Headers from "../Component/Headers";
import EkstrakurikulerArticle from "../Component/EkstrakurikulerArticle";
import PaginationEkstrakurikuler from "../Component/PaginationEkstrakurikuler";
import Footer from "../Component/Footer";


function EkstrakurikulerPage() {
  const [searchParams] = useSearchParams();
  const Page = searchParams.get("page");

  const [ekstrakurikuler, setEkstrakurikuler] = useState({});
  const [ekstrakurikulerFull, setEkstrakurikulerFull] = useState({});

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchData(Page);
    document.title = "Ekstrakurikuler | SMK BUDDHI";

    return () => {
      document.title = "SMK BUDDHI";
    }
  }, [searchTerm, Page]);

  const fetchData = (Page) => {
    axios.get(`${Config.Api}/api/ekstrakurikulers?populate=%2A&pagination[page]=${Page === null ? 1 : Page}&pagination[pageSize]=6&sort[1]=createdAt:DESC`)
      .then((responses) => {
        if (responses.data) {
          setEkstrakurikuler(responses.data || {});
        } else {
          console.error("Data not found in response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  const fetchDataFull = (value) => {
    axios
      .get(`${Config.Api}/api/ekstrakurikulers?populate=%2A&sort[1]=createdAt:DESC`)
      .then((response) => {
        if (response.data) {
          if(value === '') return setEkstrakurikulerFull({})
          setEkstrakurikulerFull(response.data.data.filter(dat => {
            return dat.attributes?.Ekstrakurikuler.toLowerCase().includes(value.toLowerCase());
          }) || {});
        } else {
          console.error("Data not found in response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    fetchDataFull(value);
  };

  return (
    <div className="EkstrakurikulerPage">
      <Navbar />
      <Headers title="Ekstrakurikuler" desc="Ekstrakurikuler di SMK BUDDHI" />

      <div className="container mx-auto flex flex-col py-3 justify-center items-center">

        <div className="flex px-2 mt-6 container justify-end">
          <div className="relative w-[400px]">
            <input className="w-full bg-gray-100 border rounded py-2 px-4" placeholder="Search" onChange={(e) => handleSearch(e.target.value)} />
            <AiOutlineSearch className="absolute top-2 right-2 h-6 w-6 text-gray-500" />
          </div>
        </div>

        <section data-aos="fade-down" className="pe-5 ps-5 grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3 container mx-auto">
          {ekstrakurikulerFull.length === undefined ? (Object.keys(ekstrakurikuler).length > 0 && ekstrakurikuler.data.map((data, index) => <EkstrakurikulerArticle key={index} Data={data} />))
            : Object.keys(ekstrakurikulerFull).length > 0 && ekstrakurikulerFull.map((data, index) => <EkstrakurikulerArticle key={index} Data={data} />)}
        </section>
        {Object.keys(ekstrakurikuler).length > 0 ? <PaginationEkstrakurikuler Page={Page === null ? 1 : Page} Data={ekstrakurikuler} /> : ""}

      </div>

      <footer className="bg-red-500">
        <Footer />
      </footer>
    </div>
  );
}

export default EkstrakurikulerPage;
