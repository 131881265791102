import "flowbite";
import React, { useState, useEffect } from "react";
import Dropdown from "./Dropdown";
import { NavLink } from "react-router-dom";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUser } from '@fortawesome/free-solid-svg-icons';

function Navbar({ profile, logout }) {
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const information = [
    { name: "Visi Misi", href: "/visi-misi" },
    { name: "Fasilitas", href: "/fasilitas" },
    { name: "Peraturan", href: "/peraturan" },
    { name: "News", href: "/berita" },
  ];

  const Kesiswaan = [
    { name: "Ekstrakulikuler", href: "/ekstrakurikuler" },
    { name: "Guru", href: "/guru" },
    { name: "Prestasi", href: "/prestasi" },
  ];

  const Keahlian = [
    { name: "Akuntansi Keuangan dan Lembaga (AKL)", href: "/akuntansi" },
    { name: "Desain Komunikasi Visual (DKV)", href: "/dkv" },
  ];

  useEffect(() => {
    function handleClickOutside(event) {
      if (isProfileOpen && !event.target.closest("#userDropdown") && !event.target.closest(".profile-icon")) {
        setIsProfileOpen(false);
      }
    }

    window.addEventListener("click", handleClickOutside);
    return () => window.removeEventListener("click", handleClickOutside);
  }, [isProfileOpen]);
  return (
    <nav className="bg-red-500 sticky top-0 z-50">
      <div className="container flex flex-wrap items-center justify-between mx-auto p-2">
        <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src="../img/logo/logo.png" className="h-16" alt="Buddhi_logo" />
          <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white text-white">SMK BUDDHI</span>
        </a>

        <button
          data-collapse-toggle="navbar-multi-level"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-white rounded-lg md:hidden hover:bg-gray-100 transition-all ease-in hover:text-black focus:outline-none focus:ring-2 focus:ring-gray-200"
          aria-controls="navbar-multi-level"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
          </svg>
        </button>
        <div className={`${isMenuOpen ? "" : "hidden"} w-full md:block md:w-auto`} id="navbar-multi-level">
          <ul className="flex flex-col font-medium text-white p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-red-500">
            <li>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? "flex items-center justify-between w-full bg-blue-500 px-4 py-2 rounded-lg md:border-b-2 md:border-blue-500 md:font-semibold md:text-blue-500 md:bg-transparent md:p-0 md:rounded-none"
                    : "flex items-center justify-between w-full hover:bg-blue-500 px-4 py-2 rounded-lg md:hover:border-white md:hover:border-b-2  md:hover:bg-transparent md:p-0 md:rounded-none"
                }
                aria-current="page"
              >
                Home
              </NavLink>
            </li>
            <li>
              <Dropdown trigger="Informasi">
                {information.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className={({ isActive }) => (isActive ? "flex px-4 py-2 bg-blue-500 text-white" : "flex px-4 py-2 hover:bg-blue-500 text-white")}
                  >
                    {item.name}
                  </NavLink>
                ))}
              </Dropdown>
            </li>
            <li>
              <Dropdown trigger="Kesiswaan">
                {Kesiswaan.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className={({ isActive }) => (isActive ? "flex px-4 py-2 bg-blue-500 text-white" : "flex px-4 py-2 hover:bg-blue-500 text-white")}
                  >
                    {item.name}
                  </NavLink>
                ))}
              </Dropdown>
            </li>
            <li>
              <Dropdown trigger="Program Keahlian">
                {Keahlian.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className={({ isActive }) => (isActive ? "flex px-4 py-2 bg-blue-500 text-white" : "flex px-4 py-2 hover:bg-blue-500 text-white")}
                  >
                    {item.name}
                  </NavLink>
                ))}
              </Dropdown>
            </li>
            <li>
              <NavLink
                to="/gallery"
                className={({ isActive }) =>
                  isActive
                    ? "flex items-center justify-between w-full bg-blue-500 px-4 py-2 rounded-lg md:border-b-2 md:border-blue-500 md:font-semibold md:text-blue-500 md:bg-transparent md:p-0 md:rounded-none"
                    : "flex items-center justify-between w-full hover:bg-blue-500 px-4 py-2 rounded-lg md:hover:border-white md:hover:border-b-2  md:hover:bg-transparent md:p-0 md:rounded-none"
                }
              >
                Gallery
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

// {/* <li aria-labelledby="dropdownNavbarLink">
// <button onClick={toggleMenu} id="doubleDropdownButton" data-dropdown-toggle="doubleDropdown" data-dropdown-placement="right-start" type="button" className="flex items-center justify-between w-full px-4 py-2  texthover:bg-gray-100  dark:hover:text-white">Ekstrakulikuler<svg className="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
//     <path stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
// </svg></button>
// <div id="doubleDropdown" className={`z-10 hidden text-white bg-red-500 divide-y divide-gray-100 rounded-lg shadow w-44`}>
//     <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="doubleDropdownButton">
//         <li>
//             <a href="#" className="block bg-red-500 px-4 py-2 text-white hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Accounting</a>
//         </li>
//         <li>
//             <a href="#" className="block bg-red-500 px-4 py-2 text-white hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Photography</a>
//         </li>
//     </ul>
// </div>
// </li> */}
export default Navbar;