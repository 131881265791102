function Card({ children }) {
  return <div className="mt-8 bg-white shadow-lg rounded-lg lg:w-2/5 md:mx-5 p-8">{children}</div>;
}

function VisiMisiPage() {
  return (
    <div className="space-y-10 flex flex-col justify-center items-center ">
      <div className="px-8 flex flex-col gap-10 items-center">
        {/* <img data-aos="zoom-in" src="https://dummyimage.com/1440x900/918f91/fff.png" alt="dummy" className="rounded-xl" /> */}
        <div className="mx-auto mb-20 md:p-5 md:px-0 md:m-5 md:mx-0 flex flex-col md:flex-row justify-center">
          <Card>
            <div className="font-bold text-2xl py-4 text-center">Visi</div>
            <div>
              Sekolah Menengah Kejuruan (SMK) Buddhi mempunyai visi untuk membentuk manusia yang unggul dalam intelektual, terampil dalam bekerja,
              handal dalam berwirausaha dan terpuji dalam moral.
            </div>
          </Card>
          <Card>
            <div className="font-bold text-2xl py-4 text-center">Misi</div>
            <div className="">
              Sekolah Menengah Kejuruan (SMK) Buddhi mempunyai misi:
              <ol className="list-decimal px-4">
                <li>Menyelenggarakan pendidikan dan pelatihan yang berbasis produktif.</li>
                <li>Meningkatkan kualitas organisasi dan menajemen sekolah dalam menumbuhkan semangat keunggulan dan kompetitif</li>
                <li>Meningkatkan kualitas KBM dalam mencapai kompetensi siswa berstandar nasional/internasional.</li>
              </ol>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}
export default VisiMisiPage;