import { Config } from "../config.js";
import axios from "axios";
import { useState, useEffect } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useSearchParams } from "react-router-dom";


import Navbar from "../Component/Navbar";
import Headers from "../Component/Headers";
import GalleryImage from "../Component/GalleryImage.jsx";
import PaginationGallery from "../Component/PaginationGallery";
import Footer from "../Component/Footer";


function Gallery() {
  // PAGE
  const [searchParams] = useSearchParams();
  const Page = searchParams.get("page");

  // SEARCH
  const [searchTerm, setSearchTerm] = useState("");

  // GALLERY
  const [gallery, setGallery] = useState({});
  const [galleryFull, setGalleryFull] = useState({});

  useEffect(() => {
    fetchData(Page);
    document.title = "Gallery | SMK BUDDHI";

    return () => {
      document.title = "SMK BUDDHI";
    }
  }, [searchTerm, Page]);

  const fetchData = (Page) => {
    axios
      .get(`${Config.Api}/api/galleries?populate=%2A&pagination[page]=${Page === null ? 1 : Page}&pagination[pageSize]=9&sort[1]=createdAt:DESC`)
      .then((response) => {
        if (response.data) {
          setGallery(response.data || {});
        } else {
          console.error("Data not found in response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchDataFull = (value) => {
    axios
      .get(`${Config.Api}/api/galleries?populate=%2A&sort[1]=createdAt:DESC`)
      .then((response) => {
        if (response.data) {
          if (value === '') return setGalleryFull({})
          setGalleryFull(response.data.data.filter(dat => {
            return dat.attributes?.Judul.toLowerCase().includes(value.toLowerCase());
          }) || {});
        } else {
          console.error("Data not found in response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    fetchDataFull(value);
  };


  return (
    <div className="Gallery">
      <Navbar />
      <Headers title="Gallery" desc="Foto Dokumentasi Kegiatan Sekolah" />
      <div className="space-y-10 flex flex-col justify-center items-center">

        {/* Search bar */}
        <div className="flex px-2 mt-6 container justify-end">
          <div className="relative w-[400px]">
            <input className="w-full bg-gray-100 border rounded py-2 px-4" placeholder="Search" onChange={(e) => handleSearch(e.target.value)} />
            <AiOutlineSearch className="absolute top-2 right-2 h-6 w-6 text-gray-500" />
          </div>
        </div>
        {/* Search bar end*/}

        <section className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 container mx-auto">
          {galleryFull.length === 0 || galleryFull.length === undefined ?
            (Object.keys(gallery).length > 0 && gallery.data.map((data, index) => <GalleryImage key={index} Data={data} />)) :
            (Object.keys(galleryFull).length > 0 && galleryFull.map((data, index) => <GalleryImage key={index} Data={data} />))
          }

        </section>

        {Object.keys(gallery).length > 0 ? <PaginationGallery Page={Page === null ? 1 : Page} Data={gallery} /> : ""}

      </div>
      <footer className="bg-red-500">
        <Footer />
      </footer>
    </div>
  );
}
export default Gallery;