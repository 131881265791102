function Headers({ title, desc }) {
  return (
    <header className="w-full container mx-auto">
      <div className="flex flex-col items-center py-12">
        <a className="font-bold text-gray-800 uppercase hover:text-gray-700 text-4xl md:text-5xl text-center" href="/berita">
          {title}
        </a>
        <p className="text-lg text-gray-600 text-center">{desc}</p>
      </div>
    </header>
  );
}

export default Headers;
