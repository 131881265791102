import { Config } from '../config';
const Blog = ({ Judul }) => {
    return (
        <article className="flex flex-col shadow my-4">
            <p className="hover:opacity-75">
                <img alt='Thumbnail' src={`${Config.Api}` + Judul.attributes?.coverImg.data[0].attributes.url} />
            </p>
            <div className="bg-white flex flex-col justify-start p-6">
                <a href={"/type/" + Judul.attributes?.BlogType.toLowerCase()} className="text-blue-700 text-sm font-bold uppercase pb-4">{Judul.attributes?.BlogType}</a>
                <h1 className="text-3xl font-bold hover:text-gray-700 pb-4">{Judul.attributes?.BlogTitle}</h1>
                <p className="text-sm pb-2">
                    By <a href="/" className="font-semibold hover:text-gray-800">{Judul.attributes?.Publiser}</a>, Published on {Judul.attributes?.Date}
                </p>
                <p dangerouslySetInnerHTML={{ __html: Judul.attributes?.BlogContent.replace(/!\[(.*?)\]\((.*?)\)/g, '<img src="$2" alt="$1" />') }} />
            </div>
        </article>
    );
};

export default Blog