function UrlSafeString(options) {
    const _opts = {
        maxLen: 999999, // truncates beyond maxLen
        lowercaseOnly: true,
        regexRemovePattern: /((?!([a-z0-9])).)/gi, // matches opposite of [a-z0-9]
        joinString: '-', // e.g. - may be: '-', '_', '#'
        trimWhitespace: true
    };

    // Vanilla shallow merge to avoid dependencies
    if (options)
        for (const prop in options)
            if (Object.prototype.hasOwnProperty.call(options, prop))
                _opts[prop] = options[prop];

    return {
        generate: function generate() {
            const reJoinString = new RegExp(_opts.joinString + '+', 'g'),
                args = Array.prototype.slice.call(arguments);

            let tag;

            if (!args || args.length === 0)
                throw new Error('generate method must be passed at least one argument');

            // Validate, trim all arguments:
            for (let i = 0; i < args.length; i++) {
                if (typeof args[i] !== 'string')
                    throw new Error('all supplied arguments must be Strings');

                if (_opts.trimWhitespace)
                    args[i] = args[i].trim();
            }

            // Join strings and convert whitespace between words to join string
            tag = args.join(_opts.joinString);
            tag = tag.replace(/\s/g, _opts.joinString);
            if (_opts.lowercaseOnly)
                tag = tag.toLowerCase();
            // Regex away anything "unsafe", but ignore the join string!
            tag = tag.replace(_opts.regexRemovePattern, function (match) {
                if (match === _opts.joinString)
                    return match;

                return '';
            });

            // Truncate in excess of maxLen
            if (tag.length > _opts.maxLen)
                tag = tag.substring(0, _opts.maxLen);

            // Remove any duplicates of the join string using this pattern: /<join string>+/g
            tag = tag.replace(reJoinString, _opts.joinString);

            return tag;
        }
    };
}

function fromUrlSafeString(urlSafeString) {
    const stringWithSpaces = urlSafeString.replace(/-/g, ' ');
    const words = stringWithSpaces.split(' ');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    const originalString = capitalizedWords.join(' ');

    return originalString;
}

function timeToDate(time) {
    const timestamp = typeof time === 'string' ? parseInt(time, 10) : time;
    const date = new Date(timestamp);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
}

const toUrl = (title) => {
    var a = new UrlSafeString()
    var b = a.generate(title)
    return b
}

module.exports = {
    func: {
        toUrl,
        timeToDate,
        fromUrlSafeString
    }
}