import { Config } from "../config.js";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

import Navbar from "../Component/Navbar.jsx";
import Headers from "../Component/Headers.jsx";
import TopicNews from "../Component/TopicNews.jsx";
import Article from "../Component/Article.jsx";
import Pagination from "../Component/Pagination.jsx";
import Footer from "../Component/Footer.jsx";
import { AiOutlineSearch } from "react-icons/ai";

function Berita() {
  // PAGE
  const [searchParams] = useSearchParams();
  const Page = searchParams.get("page");

  // SEARCH
  const [searchTerm, setSearchTerm] = useState("");


  // BERITA
  const [articles, setArticles] = useState({});
  const [articlesFull, setArticlesFull] = useState({});

  useEffect(() => {
    fetchData(Page);
    document.title = "Berita | SMK BUDDHI";

    return () => {
      document.title = "SMK BUDDHI";
    }
  }, [Page, articlesFull, searchTerm]);

  const fetchData = (Page) => {
    axios.get(`${Config.Api}/api/blogs?populate=%2A&pagination[page]=${Page === null ? 1 : Page}&pagination[pageSize]=3&sort[1]=createdAt:DESC`)
      .then((responses) => {
        if (responses.data) {
          setArticles(responses.data || {});
        } else {
          console.error("Data not found in response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  const fetchDataFull = (value) => {
    axios
      .get(`${Config.Api}/api/blogs?populate=%2A&sort[1]=createdAt:DESC`)
      .then((response) => {
        if (response.data) {
          if(value === '') return setArticlesFull({})
          setArticlesFull(response.data.data.filter(dat => {
            return dat.attributes?.BlogTitle.toLowerCase().includes(value.toLowerCase());
          }) || {});
        } else {
          console.error("Data not found in response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    fetchDataFull(value);
  };

  return (
    <div className="Berita">
      <Navbar />

      <Headers title="SMK BUDDHI NEWS" desc="Berita Berita Terbaru" />
      <TopicNews />

      {/* Search bar */}
      <div data-aos="fade-down" data-aos-duration="3000" className="flex md:hidden px-2 mt-6 mx-auto container">
        <div className="relative w-full">
          <input className="w-full bg-gray-100 border rounded py-2 px-4" placeholder="Search" onChange={(e) => handleSearch(e.target.value)} />
          <AiOutlineSearch className="absolute top-2 right-2 h-6 w-6 text-gray-500" />
        </div>
      </div>
      {/* Search bar end*/}

      <div className="container mx-auto flex flex-wrap py-3">
        <section className="w-full md:w-2/3 flex flex-col items-center px-3">
          {articlesFull.length === 0 || articlesFull.length === undefined ?
            (Object.keys(articles).length > 0 && articles.data.map((data, index) => <Article key={index} Data={data} />)) :
            (Object.keys(articlesFull).length > 0 && articlesFull.map((data, index) => <Article key={index} Data={data} />))
          }
          {Object.keys(articles).length > 0 ? <Pagination Page={Page === null ? 1 : Page} Data={articles} /> : ""}
        </section>

        <aside data-aos="fade-down" data-aos-duration="3000" className="w-full md:w-1/3 flex flex-col items-center px-3">
          {/* Search bar */}
          <div className="hidden md:flex px-2 mt-6 mx-auto container">
            <div className="relative w-full">
              <input className="w-full bg-gray-100 border rounded py-2 px-4" placeholder="Search" onChange={(e) => handleSearch(e.target.value)} />
              <AiOutlineSearch className="absolute top-2 right-2 h-6 w-6 text-gray-500" />
            </div>
          </div>
          {/* Search bar end*/}

          <div className="w-full bg-white shadow flex flex-col my-4 p-6">
            <p className="pb-2">
              Sekolah Menengah Kejuruan (SMK) Buddhi mempunyai visi untuk membentuk manusia yang unggul dalam intelektual, terampil dalam bekerja, handal dalam berwirausaha dan terpuji dalam moral.
            </p>
            <li>Menyelenggarakan pendidikan dan pelatihan yang berbasis produktif.</li>
            <li>Meningkatkan kualitas organisasi dan menajemen sekolah dalam menumbuhkan semangat keunggulan dan kompetitif</li>
            <li>Meningkatkan kualitas KBM dalam mencapai kompetensi siswa berstandar nasional/internasional.</li>
            <a
              href="https://www.instagram.com/buddhivhs/"
              className="w-full bg-blue-800 text-white font-bold text-sm uppercase rounded hover:bg-blue-700 flex items-center justify-center px-2 py-3 mt-4"
            >
              Get to know us
            </a>
          </div>
        </aside>
      </div>
      <footer className="bg-red-500">
        <Footer />
      </footer>
    </div>
  );
}

export default Berita;
