import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function PaginationPrestasi({ Page, Data }) {
    if (parseInt(Page) > Data.meta.pagination.pageCount) window.location.href = '/prestasi';
    return (
        <div className="flex items-center py-8">
            {parseInt(Page) - 1 < Data.meta.pagination.pageCount && parseInt(Page) > 1 ? (<a href={`/prestasi?page=${parseInt(Page) - 1}`} className="h-10 w-10 font-semibold text-gray-800 hover:text-gray-900 text-sm flex items-center justify-center ml-3"><FontAwesomeIcon icon={faArrowLeft} />Back</a>) : ""}
            {parseInt(Page) - 2 < Data.meta.pagination.pageCount && parseInt(Page) - 1 > 1 ? (<a href={`/prestasi?page=${parseInt(Page) - 2}`} className="h-10 w-10 font-semibold text-gray-800 hover:bg-blue-600 hover:text-white text-sm flex items-center justify-center" > {parseInt(Page) - 2}</a >) : ""}
            {parseInt(Page) - 1 < Data.meta.pagination.pageCount && parseInt(Page) > 1 ? (<a href={`/prestasi?page=${parseInt(Page) - 1}`} className="h-10 w-10 font-semibold text-gray-800 hover:bg-blue-600 hover:text-white text-sm flex items-center justify-center" > {parseInt(Page) - 1}</a >) : ""}
            <a href="/prestasi" className="h-10 w-10 bg-blue-800 hover:bg-blue-600 font-semibold text-white text-sm flex items-center justify-center">{parseInt(Page)}</a>
            {parseInt(Page) + 1 < Data.meta.pagination.pageCount + 1 && parseInt(Page) + 1 > 1 ? (<a href={`/prestasi?page=${parseInt(Page) + 1}`} className="h-10 w-10 font-semibold text-gray-800 hover:bg-blue-600 hover:text-white text-sm flex items-center justify-center" > {parseInt(Page) + 1}</a >) : ""}
            {parseInt(Page) + 2 < Data.meta.pagination.pageCount + 1 && parseInt(Page) + 1 > 1 ? (<a href={`/prestasi?page=${parseInt(Page) + 2}`} className="h-10 w-10 font-semibold text-gray-800 hover:bg-blue-600 hover:text-white text-sm flex items-center justify-center" > {parseInt(Page) + 2}</a >) : ""}
            {parseInt(Page) + 1 < Data.meta.pagination.pageCount + 1 && parseInt(Page) + 1 > 1 ? (<a href={`/prestasi?page=${parseInt(Page) + 1}`} className="h-10 w-10 font-semibold text-gray-800 hover:text-gray-900 text-sm flex items-center justify-center ml-3">Next <FontAwesomeIcon icon={faArrowRight} /></a>) : ""}
        </div>
    );
}

export default PaginationPrestasi