
function Sidebar() {
  return (
    <aside data-aos="fade-down" data-aos-duration="3000" className="w-full md:w-1/3 flex flex-col items-center px-3">
      <div className="w-full bg-white shadow flex flex-col my-4 p-6">
        <p className="pb-2">
          Sekolah Menengah Kejuruan (SMK) Buddhi mempunyai visi untuk membentuk manusia yang unggul dalam intelektual, terampil dalam bekerja, handal dalam berwirausaha dan terpuji dalam moral.
        </p>
        <li>Menyelenggarakan pendidikan dan pelatihan yang berbasis produktif.</li>
        <li>Meningkatkan kualitas organisasi dan menajemen sekolah dalam menumbuhkan semangat keunggulan dan kompetitif</li>
        <li>Meningkatkan kualitas KBM dalam mencapai kompetensi siswa berstandar nasional/internasional.</li>
        <a
          href="https://www.instagram.com/buddhivhs/"
          className="w-full bg-blue-800 text-white font-bold text-sm uppercase rounded hover:bg-blue-700 flex items-center justify-center px-2 py-3 mt-4"
        >
          Get to know us
        </a>
      </div>
    </aside>
  );
}

export default Sidebar;
