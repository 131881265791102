import { Config } from "../config";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { func } from "../Function/function";

import Navbar from "../Component/Navbar";
import Headers from "../Component/Headers";
import TopicNews from "../Component/TopicNews";
import Blog from "../Component/Blog";
import Button from "../Component/Button";
import Sidebar from "../Component/Sidebar";
import Footer from "../Component/Footer";

function BeritaBlog() {

  const { Judul } = useParams();
  const [articles, setArticles] = useState({});
  const [total, setTotal] = useState([]);

  useEffect(() => {
    Promise.all([axios.get(`${Config.Api}/api/blogs?populate=%2A`)])
      .then((responses) => {
        if (responses[0].data) {
          setTotal(responses[0].data.meta.pagination);
          const targetBlog = responses[0].data.data.find((i) => func.toUrl(i.attributes.BlogTitle) === Judul);
          if (targetBlog === undefined) return (window.location.href = "/berita");
          setArticles(targetBlog || {});
        } else {
          console.error("Data not found in response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    document.title = `Berita ${Judul} | SMK BUDDHI`;

    return () => {
      document.title = "SMK BUDDHI";
    }
  }, [Judul]);

  return (
    <div className="BeritaBlog">
      <Navbar />
      <Headers title="SMK BUDDHI NEWS" desc="Berita Berita Terbaru" />
      <TopicNews />
      <div className="container mx-auto flex flex-wrap py-6">
        <section className="w-full md:w-2/3 flex flex-col items-center px-3">
          <Blog Judul={articles} />
          {articles?.id ? <Button Data={articles} Total={total} /> : ""}
        </section>
        <Sidebar />
      </div>
      <footer className="bg-red-500">
        <Footer />
      </footer>
    </div>
  );
}

export default BeritaBlog;
