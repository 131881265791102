import { Config } from '../config';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

function TopicNews() {
    const [type, setType] = useState({});
    useEffect(() => {
        Promise.all([
            axios.get(`${Config.Api}/api/blogs?populate=%2A`)
        ])
            .then((responses) => {
                if (responses[0].data) {
                    const targetBlog = responses[0].data.data
                    const get = [...new Set(targetBlog.map(blog => blog.attributes.BlogType))];
                    setType(get || {});
                } else {
                    console.error('Data not found in response.');
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen(!open);
    };

    return (
        <nav className="w-full py-4 border-t border-b bg-gray-100" x-data="{ open: false }">
            <div className="block sm:hidden">
                <a
                    href="/"
                    className="block md:hidden text-base font-bold uppercase text-center flex justify-center items-center"
                    onClick={toggleOpen}
                >
                    Topics {open ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                </a>
            </div>
            <div className={`sm:flex ${open ? 'block' : 'hidden'} w-full flex-grow sm:flex sm:items-center sm:w-auto`}>
                <div className="w-full container mx-auto flex flex-col sm:flex-row items-center justify-center text-sm font-bold uppercase mt-0 px-6 py-2">
                    {Object.keys(type).length > 0 && type.map((data, index) => (
                        <a key={index} href={"/type/" + data.toLowerCase()} className="hover:bg-gray-400 rounded py-2 px-4 mx-2">{data}</a>
                    ))}
                </div>
            </div>
        </nav>
    );
}

export default TopicNews