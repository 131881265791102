import { Config } from "../config";
// import { func } from "../Function/function";

function EkstrakurikulerArticle({ Data }) {
  return (
    <article className="flex flex-col shadow-xl my-4 rounded-xl overflow-hidden">
      {/* Article Image */}
      <a href={"type/ekstrakurikuler"} className="hover:opacity-75">
        <img src={`${Config.Api}` + Data.attributes?.Thumb.data[0].attributes.url} alt="Gambar Artikel" className="bg-fixed" />
      </a>
      <div className="bg-white flex flex-col justify-start p-6">
        <a href={"type/ekstrakurikuler"} className="text-3xl font-bold hover:text-gray-700 pb-4">
          {Data.attributes.Ekstrakurikuler}
        </a>
        {/* <a href={"berita/" + func.toUrl(Data.attributes.Ekstrakurikuler)} className="uppercase text-gray-800 hover:text-black">
          Continue Reading <FontAwesomeIcon icon={faArrowRight} />
        </a> */}
      </div>
    </article>
  );
}

export default EkstrakurikulerArticle;
