import { Config } from "../config.js";
import axios from "axios";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import Navbar from "../Component/Navbar";
import Headers from "../Component/Headers";
import Footer from "../Component/Footer";
import PaginationGuru from "../Component/PaginationGuru";

function ImageDummy({ data }) {
  return (
    <div className="bg-white shadow-xl py-6 px-8 rounded-lg">
      <div data-aos="fade-down" className="flex flex-col gap-y-4 justify-center items-center">
        <img src={`${Config.Api}` + data.attributes?.Thumb.data[0].attributes.url} alt="Guru" className="rounded-lg" />
        <div className="">{data.attributes?.NameTeacher}</div>
        <div className="text-center">{data.attributes?.Field}</div>
      </div>
    </div>

  );
}

function Guru() {
  // PAGE
  const [searchParams] = useSearchParams();
  const Page = searchParams.get("page");

  // GALLERY
  const [guru, setGuru] = useState({});

  useEffect(() => {
    fetchData(Page);
    document.title = "Guru | SMK BUDDHI";

    return () => {
      document.title = "SMK BUDDHI";
    }
  }, [Page]);

  const fetchData = (Page) => {
    axios
      .get(`${Config.Api}/api/teachers?populate=%2A&pagination[page]=${Page === null ? 1 : Page}&pagination[pageSize]=8&sort[1]=createdAt:DESC`)
      .then((response) => {
        if (response.data) {
          setGuru(response.data || {});
        } else {
          console.error("Data not found in response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  return (
    <div className="Guru">
      <Navbar />
      <Headers title="Guru" desc="Guru Guru di SMK Buddhi" />
      <div className="space-y-10 pt-6 flex flex-col justify-center items-center">
        <section className="ps-5 pe-5 grid grid-cols-1 gap-10 md:grid-cols-3 xl:grid-cols-4 container mx-auto pb-10">
          {Object.keys(guru).length > 0 && guru.data.map((data, index) => <ImageDummy key={index} data={data} />)}

        </section>

        {Object.keys(guru).length > 0 ? <PaginationGuru Page={Page === null ? 1 : Page} Data={guru} /> : ""}
      </div>
      <footer className="bg-red-500">
        <Footer />
      </footer>
    </div>
  );
}
export default Guru;
