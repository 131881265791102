import { Config } from "../config";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSearchParams, useParams } from "react-router-dom";

import Navbar from "../Component/Navbar";
import Headers from "../Component/Headers";
import TopicNews from "../Component/TopicNews";
import Article from "../Component/Article";
import Pagination from "../Component/Pagination";
import Sidebar from "../Component/Sidebar";
import Footer from "../Component/Footer";

function BeritaType() {

  const [searchParams] = useSearchParams();
  const { Type } = useParams();
  const Page = searchParams.get("page");

  // BERITA
  const [articles, setArticles] = useState({});

  useEffect(() => {
    Promise.all([
      axios.get(
        `${Config.Api}/api/blogs?filters[BlogType][$eqi]=${Type}&populate=%2A&pagination[page]=${Page === null ? 1 : Page
        }&pagination[pageSize]=3&sort[1]=createdAt:DESC`
      ),
    ])
      .then((responses) => {
        if (responses[0].data) {
          setArticles(responses[0].data || {});
        } else {
          console.error("Data not found in response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
      document.title = `Berita ${Type} | SMK BUDDHI`;

      return () => {
        document.title = "SMK BUDDHI";
      }
  }, [Page, Type]);


  return (
    <div className="BeritaType">
      <Navbar />
      <Headers title="SMK BUDDHI NEWS" desc="Berita Berita Terbaru" />
      <TopicNews />
      <div className="container mx-auto flex flex-wrap py-6">
        <section className="w-full md:w-2/3 flex flex-col items-center px-3">
          {Object.keys(articles).length > 0 && articles.data.map((data, index) => <Article key={index} Data={data} />)}
          {Object.keys(articles).length > 0 ? <Pagination Page={Page === null ? 1 : Page} Data={articles} /> : ""}
        </section>
        <Sidebar />
      </div>
      <footer className="bg-red-500">
        <Footer />
      </footer>
    </div>
  );
}

export default BeritaType;
