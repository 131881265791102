// MODULE
import { Config } from "../config";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
// CSS
import "../index.css";
import "font-awesome/css/font-awesome.min.css";

// SWIPER
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "animate.css";

// COUNTAINER
import Navbar from "../Component/Navbar.jsx";
import Caroussel from "../Component/Caroussel.jsx";
import Card from "../Component/Card.jsx";
import CardPrestasi from "../Component/CardPrestasi.jsx";
import Profile from "../Component/Profile.jsx";
import Footer from "../Component/Footer.jsx";

function Dashboard() {
  const [DataGuru, setGuru] = useState([]);
  const [articles, setArticles] = useState({});
  const [prestasi, setPrestasi] = useState({});

  useEffect(() => {
    Promise.all([
      axios.get(`${Config.Api}/api/blogs?populate=%2A&pagination[limit]=4&sort[1]=createdAt:DESC`),
      axios.get(`${Config.Api}/api/teachers?populate=%2A&sort[1]=createdAt:DESC&pagination[pageSize]=30`),
      axios.get(`${Config.Api}/api/prestasis?populate=%2A&pagination[limit]=3&sort[1]=createdAt:DESC`),
    ])
      .then((responses) => {
        if (responses[1].data) {
          setGuru(responses[1].data.data || {});
        } else {
          console.error("Data not found in response.");
        }

        if (responses[0].data) {
          setArticles(responses[0].data.data || {});
        } else {
          console.error("Data not found in response.");
        }

        if (responses[2].data) {
          setPrestasi(responses[2].data.data || {});
        } else {
          console.error("Data not found in response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    document.title = "SMK BUDDHI";

    return () => {
      document.title = "SMK BUDDHI";
    }
  }, []);
  console.log(DataGuru)
  return (
    <div className="Dashboard">
      <Navbar />
      <Caroussel />
      <h3 className="text-4xl text-center my-px uppercase font-bold pt-14">Prestasi</h3>
      <p className="text-center my-px">Berita, Kegiatan Siswa, dan Artikel Sekolah.</p>
      <br />
      <div className="flex justify-center items-center">
        <section className="ps-5 pe-5 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 container mx-auto">
          {/* <div className="grid grid-cols-1 gap-10 md:grid-cols-3 xl:grid-cols-4"> */}
          {Object.keys(prestasi).length > 0 && prestasi.map((data, index) => <CardPrestasi key={index} data={data} />)}
        </section>
      </div>
      <br />
      <div className="flex justify-center">
        <Link to="/prestasi">
          <button
            type="button"
            className="bg-blue-500 text-white focus:ring-4 focus:outline-none focus:ring-blue-30text-white hover:bg-blue-600 n font-medium rounded-lg text-lg px-3 py-4 text-center mr-2 mb-2"
          >
            Lihat Prestasi Lainnya
          </button>
        </Link>
      </div>
      <div className="container mx-auto">{Object.keys(DataGuru).length > 0 ? <Profile Guru={DataGuru} /> : ""}</div>

      <br />
      <h3 className="text-4xl text-center my-px uppercase font-bold">Publikasi</h3>
      <p className="text-center my-px">Berita, Kegiatan Siswa, dan Artikel Sekolah.</p>
      <br />
      <div className="flex justify-center items-center">
        <section className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4 container mx-auto">
          {/* <div className="grid grid-cols-1 gap-10 md:grid-cols-3 xl:grid-cols-4"> */}
          {Object.keys(articles).length > 0 && articles.map((data, index) => <Card key={index} data={data} />)}
        </section>
      </div>

      <br />
      <div className="flex justify-center">
        <Link to="/berita">
          <button
            type="button"
            className="bg-blue-500 text-white focus:ring-4 focus:outline-none focus:ring-blue-30text-white hover:bg-blue-600 n font-medium rounded-lg text-lg px-3 py-4 text-center mr-2 mb-2"
          >
            Lihat Berita Lainnya
          </button>
        </Link>
      </div>
      <br />

      <footer className="bg-red-500">
        <Footer />
      </footer>
    </div>
  );
}

export default Dashboard;
