import { Config } from "../config";
import React, { useState, useEffect } from "react";
import axios from "axios";

import Navbar from "../Component/Navbar";
import Headers from "../Component/Headers";
import Keahlian from "../Component/Keahlian";
import Footer from "../Component/Footer";


function Akuntansi() {

  const [DataKeahlian, setKeahlian] = useState([]);

  useEffect(() => {
    Promise.all([
      axios.get(`${Config.Api}/api/keahlians?populate=%2A`),
    ])
      .then((responses) => {
        if (responses[0].data) {
          const targetKeahlian = responses[0].data.data.find((i) => i.attributes.Program === 'Akuntansi Keuangan dan Lembaga (AKL)');
          setKeahlian(targetKeahlian || {});
        } else {
          console.error("Data not found in response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    document.title = "Keahlian Akuntansi | SMK BUDDHI";

    return () => {
      document.title = "SMK BUDDHI";
    }
  }, []);

  return (
    <div className="Akuntansi">
      <Navbar />
      <Headers title="Akuntansi Keuangan dan Lembaga (AKL)" desc="Program Keahlian Akuntansi Keuangan dan Lembaga (AKL) SMK Buddhi" />
      <Keahlian
        data={DataKeahlian}
      />
      <footer className="bg-red-500">
        <Footer />
      </footer>
    </div>
  );
}
export default Akuntansi;
