// import { Config } from "../config";
// import axios from "axios";
// import React, { useState, useEffect } from "react";
function Footer() {
  // const [ekstrakurikuler, setEkstrakurikuler] = useState({});
  // useEffect(() => {
  //   Promise.all([
  //     axios.get(`${Config.Api}/api/ekstrakurikulers?populate=%2A&pagination[limit]=3`),
  //   ])
  //     .then((responses) => {
  //       if (responses[0].data) {
  //         setEkstrakurikuler(responses[0].data || {});
  //       } else {
  //         console.error("Data not found in response.");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // }, []);

  // function ClubNActivity({ data }) {
  //   return (
  //     <p>
  //       <a
  //         href={`/type/${data.attributes.Ekstrakurikuler}`}
  //         className="font-semibold justify-center transition-colors duration-300 dark:hover:text-blue-400 hover:underline hover:text-blue-500"
  //         id="footertext"
  //       >
  //         {data.attributes.Ekstrakurikuler}
  //       </a>
  //     </p>
  //   );
  // }
  return (
    <div className="py-12 text-white">
      <div className="container mx-auto divide-y">
        <div className="flex justify-center items-center py-2 flex-col lg:flex-row">
          <div className="flex flex-col text-center items-center pb-5 gap-16 justify-center lg:items-start lg:text-start lg:flex-row">
            <div data-aos="fade-down" className="w-1/3">
              <h4 className="font-semibold">Alamat</h4>
              <p>Jl. Imam Bonjol No.41, RT.002/RW.003, Karawaci, Kec. Tangerang, Banten, 15115.</p>
            </div>
            <div data-aos="fade-down" className="w-1/4">
              <h4 className="font-semibold">Kontak</h4>
              <p>Telp : (021) 5517853</p>
            </div>
            <div data-aos="fade-down" className="w-1/5">
              <h4 className="font-semibold">Instagram</h4>
              <p><a href="https://www.instagram.com/buddhivhs/">SMK Buddhi</a></p>
              <p><a href="https://www.instagram.com/fotografi.smkbuddhi/">Fotografi</a></p>
              <p><a href="https://www.instagram.com/tefa_buddhi/">Teaching Factory (Tefa)</a></p>
              {/* {Object.keys(ekstrakurikuler).length > 0 && ekstrakurikuler.data.map((data, index) => <ClubNActivity key={index} data={data} />)} */}
            </div>
          </div>

          <div className="overflow-hidden space-y-4">
            <h1 className="max-w-lg text-xl font-semibold tracking-tight xl:text-2xl text-white">
              Subscribe our Youtube to get update.
            </h1>
            {/* <div className="flex flex-col mx-auto mt-6 md:space-y-0 md:flex-row">
              <input
                id="email"
                type="text"
                className="px-3 py-2 w-full text-gray-700 bg-white border rounded-l-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-opacity-40 focus:ring-blue-300"
                placeholder="Email Address"
              />
              <button className="w-full px-4 py-2.5 text-sm font-medium tracking-wider text-white transition-colors duration-300 transform md:w-auto focus:outline-none bg-blue-500 rounded-r-lg hover:bg-blue-600 focus:ring focus:ring-gray-300 focus:ring-opacity-80">
                Subscribe
              </button>
            </div> */}
            <iframe
              title="Youtube"
              height="250px"
              className="w-full rounded-lg"
              src="https://www.youtube.com/embed/Hw-8jZ9JO3k?si=MlV9b_Ue55teWBcN"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center text-white text-lg py-4 copyright capitalize italic">
          <p>copyright &copy;smkbuddhi 2024</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;

// <div className="py-12 text-white">
//   <div className="container mx-auto px-4 divide-y">
//     <div className="flex fle">
//       <div data-aos="fade-left" className="hidden lg:mt-0 lg:col-span-5 lg:flex">
//         <img src="img/logo/logo.png" alt="mockup" className="h-32 w-40" />
//       </div>

//       <div className="flex flex-col lg:flex-row justify-center py-4 items-center text-center lg:items-start lg:text-start gap-4">
//         <div data-aos="fade-down" className="w-1/2">
//           <h4 className="font-semibold">Alamat</h4>
//           <p>Jl. Imam Bonjol No.41, RT.002/RW.003, Karawaci, Kec. Tangerang, Banten, 15115.</p>
//         </div>

//         <div data-aos="fade-down" data-aos-duration="1000" className="w-1/4 flex-col flex">
//           <h4 className="font-semibold">Kontak</h4>
//           <span>Telp : (021) 5517853</span>
//           <span>Instagram : @buddhivhs</span>
//         </div>

//         <div data-aos="fade-down" data-aos-duration="1100" className="w-2/3">
//           <h4 className="font-semibold">Club & Activity</h4>
//           {Object.keys(ekstrakurikuler).length > 0 && ekstrakurikuler.data.map((data, index) => <ClubNActivity key={index} data={data} />)}
//         </div>
//       </div>
//     </div>

//     <div className="flex flex-col items-center justify-center text-white text-lg py-4">
//       <p>copyright (copyright nya bisa di ganti pake nama lu) &copy; 2024</p>
//     </div>
//   </div>
// </div>
